.contact {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
}

.inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 80px;
    max-width: 1440px;
}

.left {
    height: 100%;
    width: 56%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 40px;
}

.right {
    height: 100%;
    width: 44%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imageContainer {
    min-height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image {
    min-height: 500px;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
}

.subtitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--accent);
    margin-bottom: 18px;
    text-align: center;
}

.title {
    font-size: 44px;
    font-family: "Ogg";
    letter-spacing: 4px;
    margin-bottom: 48px;
    text-align: center;
}

.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subtitleSection {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 18px;
    text-align: center;
    margin-bottom: 24px;
}

.text {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    margin-top: 24px;
}

.call {
    padding: 12px 42px;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    font-weight: 500;
    background-color: white;
    color: black;
    border: 2px solid white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
}

.call:hover {
    transition: 0.5s ease;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

.directions {
    padding: 12px 42px;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    border: 2px solid white;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
}

.directions:hover {
    transition: 0.5s ease;
    background-color: white;
    color: black;
}

@media screen and (max-width: 1024px) {
    .inner {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 80px 24px;
        row-gap: 48px;
    }

    .left {
        width: 100%;
        padding-right: 0;
    }

    .right {
        width: 100%;
        align-items: flex-start;
    }

    .section {
        align-items: flex-start;
    }
    
    .text {
        font-size: 18px;
        font-weight: 300;
        text-align: start;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 36px;
    }

    .buttonsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        row-gap: 12px;
        margin-top: 24px;
    }    
}