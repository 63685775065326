.menus {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--black);
}

.hero {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 0;
    background-color: var(--black);
    padding: 24px;
}

.heroBackgroundContainer {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 500px;
    max-height: 500px;
    width: 100%;
    z-index: -1;
    user-select: none;
}

.heroBackground {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
    filter: brightness(60%);
}

.body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 0 24px;
    flex-wrap: wrap;
}

.name {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: "Ogg";
}

.option {
    min-width: 300px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s ease;
}

.option:hover .image {
    transition: 0.5s ease;
    filter: brightness(60%);
}

.imageContainer {
    min-height: 320px;
    height: 320px;
    max-height: 320px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 24px;
    transition: 0.5s ease;
}

.image {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
    transition: 0.5s ease;
}

.title {
    font-size: 54px;
    font-family: "Ogg";
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .body {
        padding: 80px 24px;
    }
}