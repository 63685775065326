.gallery {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--black);
    position: relative;
}

.hero {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 0;
    background-color: var(--black);
    padding: 24px;
}

.heroBackgroundContainer {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 500px;
    max-height: 500px;
    width: 100%;
    z-index: -1;
    user-select: none;
}

.heroBackground {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
    filter: brightness(60%);
}

.title {
    font-size: 54px;
    font-family: "Ogg";
    text-align: center;
}

.body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-top: 40px;
}

.images {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(auto, 400px));
    gap: 24px;
    padding: 24px;
    justify-content: center;
    align-content: center;
}  

.imageContainer {
    min-height: 400px;
    max-height: 400px;
    max-width: 400px;
    height: 400px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
    filter: saturate(140%);
}