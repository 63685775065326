.privacyPolicyBackdrop {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background-color: var(--backdrop);
}

.privacyPolicy {
    min-height: 400px;
    height: 80%;
    width: 80%;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
    padding: 48px;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.privacyPolicyTitle {
    font-size: 28px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

.lastUpdated {
    font-size: 18px;
}

.body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}

.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1.4px solid white;
}

.sectionTitle {
    font-size: 22px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
}

.sectionText {
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
}

.footerButton {
    padding: 8px 36px;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    font-weight: 500;
    background-color: white;
    color: black;
    border: 2px solid white;
}

.footerButton:hover {
    transition: 0.5s ease;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

@media screen and (max-width: 650px) {
    .privacyPolicy {
        width: 96%;
    }
}

@media screen and (max-width: 450px) {
    .privacyPolicy {
        padding: 24px;
    }

    .privacyPolicyTitle {
        font-size: 6vw;
    }

    .lastUpdated {
        font-size: 4vw;
    }
}