.menu {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--black);
}

.hero {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 0;
    background-color: var(--black);
    padding: 24px;
}

.heroBackgroundContainer {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 500px;
    max-height: 500px;
    width: 100%;
    z-index: -1;
    user-select: none;
}

.heroBackground {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
    filter: brightness(60%);
    object-position: center;
}

.title {
    font-size: 54px;
    font-family: "Ogg";
    text-align: center;
}

.body {
    min-height: calc(100vh - 100px);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 80px;
}

.menuBody {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background-color: var(--gray);
    padding: 40px;
    column-gap: 60px;
    margin-top: 36px;
    position: relative;
}

.left {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 40px;
}

.right {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 40px;
}

.options {
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 48px;
}

.option {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 2px;
    transition: 0.5s ease;
}

.selected {
    transition: 0.5s ease;
    border-bottom: 2px solid white;
}

.optionMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 36px;
}

.optionUpper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.name {
    font-size: 18px;
    font-family: "Ogg";
}

.price {
    font-size: 18px;
    color: var(--accent);
    font-family: "Ogg";
}

.desc {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
    color: var(--whiteSubtext);
    font-weight: 300;
    text-align: start;
}

.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.sectionTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 24px;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding-bottom: 24px;
    border-bottom: 1.4px solid white;
    font-family: "Ogg";
}

@media screen and (max-width: 1024px) {
    .menuBody {
        flex-direction: column;
        padding: 40px 0;
    }

    .left {
        width: 100%;
    }

    .right {
        width: 100%;
    }

    .options {
        width: 100%;
        height: auto;
        flex-direction: column;
        row-gap: 24px;
    }

    .body {
        padding: 80px 24px;
        padding-top: 48px;
    }
}