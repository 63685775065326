:root {
    --accent: #067a85;
    --whiteSubtext: rgba(255, 255, 255, 0.8);
    --backdrop: rgba(0, 0, 0, 0.3);
    --shadow: rgba(0, 0, 0, 0.1);
    --black: #0a0b0a;
}

.app {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #000000;
}