.header {
    min-height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 48px;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 95;
    overflow: hidden;
}

.logo {
    min-height: 72px;
    height: 72px;
    max-height: 72px;
    width: auto;
}

.left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.navText {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    font-family: "Jost";
    letter-spacing: 1px;
    transition: 0.3s ease;
    padding: 6px 0;
}

.navText::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--accent);
    transition: all 0.3s ease;
}

.navText:hover::before {
    width: 100%;
}

.navButton {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
    padding: 10px 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    border: 2px solid white;
    transition: 0.5s ease;
    font-family: "Jost";
}

.navButton:hover {
    transition: 0.5s ease;
    background-color: white;
    color: black;
}

.mobileNav {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:  space-between;
    align-items: center;
    position: relative;
    z-index: 99;
    display: none;
}

.mobileLogo {
    min-height: 54px;
    height: 54px;
    max-height: 54px;
    width: auto;
}

.mobileMenuBackdrop {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--backdrop);
    z-index: 97;
    display: none;
}

.mobileMenu {
    min-height: 700px;
    height: 100vh;
    max-width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 98;
    transition: 0.5s ease;
    transform: translateX(100%);
}

.mobileMenuHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 36px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.mobileMenuBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 48px 36px;
    row-gap: 12px;
}

.mobileMenuOption {
    font-size: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.socials {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 36px;
}

.copyright {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 36px;
}

.contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 36px;
    row-gap: 12px;
}

.contactOption {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 12px;
}

.contactText {
    font-size: 14px;
}

@media screen and (max-width: 1024px) {
    .left, .center, .right{
        display: none;
        z-index: -1;
    }

    .mobileNav {
        display: flex;
        z-index: 90;
    }

    .mobileMenuBackdrop {
        display: flex;
    }
}