.cookiesBackdrop {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background-color: var(--backdrop);
}

.cookies {
    height: 260px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 24px;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cookiesTitle {
    font-size: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}

.cookiesSubtitle {
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.bodyText {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 24px;
}

.bodyOption {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bodyOptionNon {
    height: 100%;
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s linear;
    user-select: none;
}

.bodyOptionSelected {
    height: 100%;
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s linear;
    user-select: none;
    background-color: white;
    color: black;
}

.footerButton {
    height: 36px;
    width: 100%;
    cursor: pointer;
    transition: 0.5s ease;
    letter-spacing: 2px;
    font-weight: 500;
    background-color: white;
    color: black;
    border: 2px solid white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.footerButton:hover {
    transition: 0.5s ease;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

@media screen and (max-width: 450px) {
    .cookies {
        width: 96%;
    }
}