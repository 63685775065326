.footer {
    height: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 80px;
}

.upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 48px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.lower {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
}

.option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 220px;
}

.title {
    text-align: center;
    font-size: 18px;
    font-family: "Ogg";
    margin-bottom: 18px;
}

.desc {
    text-align: center;
    font-size: 16px;
}

.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
}

.social {
    cursor: pointer;
}

.footerLeft {
    height: 100%;
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footerMid {
    height: 100%;
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerRight {
    height: 100%;
    width: 33.33%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.footerText {
    font-size: 16px;
    color: white;
    padding: 12px 0;
    text-align: center;
    color: var(--whiteSubtext);
}

.footerRightText {
    font-size: 16px;
    color: white;
    padding: 8px 0;
    cursor: pointer;
    margin-left: 12px;
    text-align: center;
    color: var(--whiteSubtext);
}

@media screen and (max-width: 1024px) {
    .footer {
        min-height: 280px;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 24px;
    }

    .upper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 48px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        row-gap: 48px;
    }
    
    .lower {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0;
    }

    .footerText {
        font-size: 16px;
        color: white;
        padding: 12px 0;
        text-align: start;
        color: var(--whiteSubtext);
    }
    
    .footerRightText {
        font-size: 16px;
        color: white;
        padding: 8px 0;
        cursor: pointer;
        margin-left: 12px;
        text-align: start;
        color: var(--whiteSubtext);
    }

    .footerLeft, .footerMid, .footerRight {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .footerLeft {
        order: 2;
    }

    .footerMid {
        order: 1;
    }

    .footerRight {
        order: 3;
    }

    .option, .title, .desc {
        max-width: none;
        width: 100%;
    }
}