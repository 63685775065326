.about {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
}

.inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 80px; 
    max-width: 1440px;
}

.left {
    height: 100%;
    width: 44%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.right {
    height: 100%;
    width: 56%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
}

.imageContainer {
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image {
    min-height: 400px;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
}

.subtitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--accent);
    margin-bottom: 18px;
}

.title {
    font-size: 44px;
    font-family: "Ogg";
    letter-spacing: 4px;
    margin-bottom: 48px;
}

.desc {
    font-size: 20px;
    font-weight: 300;
}

@media screen and (max-width: 1024px) {
    .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px 24px;
        row-gap: 48px;
    }

    .left {
        width: 100%;
    }

    .right {
        width: 100%;
        padding-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 36px;
    }
}