.explore {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
}

.inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
    max-width: 1440px;
}


.subtitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--accent);
    margin-bottom: 18px;
}

.title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 44px;
    font-family: "Ogg";
    letter-spacing: 4px;
    margin-bottom: 80px;
}

.body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
}

.name {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: "Ogg";
}

.option {
    min-width: 240px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s ease;
}

.option:hover .image {
    transition: 0.5s ease;
    filter: brightness(60%);
}

.imageContainer {
    min-height: 320px;
    height: 320px;
    max-height: 320px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 24px;
    transition: 0.5s ease;
}

.image {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    user-select: none;
    transition: 0.5s ease;
}

@media screen and (max-width: 1024px) {
    .inner {
        padding: 80px 24px;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 36px;
    }
}