@font-face {
    font-family: 'Ogg';
    src: url('./Assets/font/Ogg/Ogg-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ogg';
    src: url('./Assets/font/Ogg/Ogg-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Ogg';
    src: url('./Assets/font/Ogg/Ogg-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('./Assets/font/Jost/Jost-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('./Assets/font/Jost/Jost-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('./Assets/font/Jost/Jost-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Jost';
    color: white;
    letter-spacing: 0px;
    line-height: 1.25;
    font-weight: 400;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    shape-rendering: geometricPrecision;
    image-rendering: optimizeQuality;
    border: none;
    background: none;
    appearance: none;
    outline: none;
}

button {
    user-select: none;
}

#root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(24, 24, 24);
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: black;
}

body {
    scrollbar-width: 10px;
    scrollbar-color: rgb(24, 24, 24) black;
}