.reviews {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
}

.inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
    max-width: 1440px;
}

.titleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin-bottom: 48px;
}

.subtitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--accent);
    margin-bottom: 18px;
}

.title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 44px;
    font-family: "Ogg";
    letter-spacing: 4px;
}

.arrows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    position: absolute;
    bottom: 12px;
    right: 0;
    user-select: none;
}

.arrow {
    cursor: pointer;
}

.review {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.desc {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 48px;
}

.name {
    font-size: 20px;
    font-weight: 300;
}

.stars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    margin-bottom: 12px;
    user-select: none;
}

.mobileArrows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    user-select: none;
    display: none;
    margin-top: 24px;
}


@media screen and (max-width: 1024px) {
    .inner {
        padding: 80px 24px;
    }

    .arrows {
        display: none;
    }

    .mobileArrows {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 36px;
    }
}